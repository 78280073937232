<template>
  <div class="text-left">
    <div class="breadcrumbBarBox p-t-10 p-b-10" v-if="0">
      <div
        class="pageCenter breadcrumbBar"
        :style="{ width: this.$oucy.pageWidth + 'px' }"
      >
        <el-breadcrumb separator="/">
          <el-breadcrumb-item
            v-for="(item, index) in levelList"
            :key="index"
            :to="item.path"
            class="breadcrumb-item"
          >
            {{ item.meta.title }}
          </el-breadcrumb-item>
        </el-breadcrumb>
        <!-- <el-button icon="el-icon-notebook-2" type="text">帮助手册</el-button> -->
      </div>
    </div>
    <!--轮播-->
    <div v-if="0">
      <el-carousel
        :height="bannerHeight + 'px'"
        arrow="always"
        :interval="5000"
      >
        <el-carousel-item v-for="item in imageBox" :key="item.id">
          <div class="carousel" @click="brandItemClick(item)">
            <img :src="$oucy.ossUrl + item.adImg" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="text-center m-t-30">
      <img
        src="@/assets/images/askBuybanner.png"
        style="width: 1280px; height: 184px"
      />
    </div>
    <!--最新求购-->
    <div class="main">
      <div class="want_main" :style="{ width: this.$oucy.pageWidth + 'px' }">
        <div class="want_left">
          <div
            class="hot_title aksBuyTabs"
            style="position: relative; background-color: #fff"
          >
            <el-tabs
              v-model="QueryFurnitureLibrary.askCategoryId"
              @tab-click="handleClick"
            >
              <el-tab-pane
                :label="v.categoryName"
                :name="v.id"
                v-for="(v, i) of buyAskCategory"
                :key="i"
              ></el-tab-pane>
            </el-tabs>
          </div>
          <div class="middle_middle">
            <div class="wangge" @click="ChangeType(2)">
              <img
                src="@/assets/icon/liebiao.png"
                class="hover"
                alt=""
                :style="type == 2 ? 'display:none' : 'display:block'"
              />
              <img
                src="@/assets/icon/liebiao_hover.png"
                alt=""
                :style="type == 2 ? 'display:block' : 'display:none'"
              />
              <span :style="type == 2 ? 'color:#2090ff' : ''">列表模式</span>
            </div>
            <div class="wangge" @click="ChangeType(1)">
              <img
                src="@/assets/icon/wangge_hover.png"
                class="hover"
                alt=""
                :style="type == 1 ? 'display:none' : 'display:block'"
              />
              <img
                src="@/assets/icon/wangge.png"
                alt=""
                :style="type == 1 ? 'display:block' : 'display:none'"
              />
              <span :style="type == 1 ? 'color:#2090ff' : ''">网格模式</span>
            </div>
          </div>
          <div class="text-left">
            <div class="" v-if="type == 1">
              <!-- @click="$oucy.go('/askBuyDetail?id=' + v.id)" -->
              <div class="box1s">
                <div
                  class="box1Box"
                  v-for="(v, i) of queryFurnitureLibraryObj.content"
                  @click="checkAuth(v)"
                  :key="i"
                >
                  <div class="box1">
                    <div v-if="getFormType(v.buyAskForms, 7)">
                      <div
                        v-for="(vvv, i) of v.buyAskForms"
                        :key="i"
                        v-if="
                          vvv.buyAskCategoryForm &&
                          vvv.buyAskCategoryForm.formType == 7
                        "
                        style="
                          background-color: #f1f1f1;
                          margin-bottom: 10px;
                        "
                      >
                        <template v-for="(vv, ii) of vvv.value" v-if="ii < 1">
                          <div class="bns">
                            
                          <img
                            :src="$oucy.ossUrl + vv.value"
                            class="bn"
                            v-if="vv.type == 'image'"
                          />
                          <video
                            :src="$oucy.ossUrl + vv.value"
                            class="bn"
                            v-if="vv.type == 'video'"
                            controls
                          ></video>
                          </div>
                        </template>
                      </div>
                    </div>
                    <div
                      v-else
                      class="text-center df-ai-c df-jc-c"
                      style="background: #f1f1f1; margin-bottom: 10px"
                    >
                      <el-image
                        style="color: #aaa; width: 200px; height: 200px"
                      >
                        <div slot="error" class="image-slot">
                          <i
                            class="el-icon-picture-outline m-t-50"
                            style="font-size: 80px"
                          ></i>
                          <div>无图</div>
                        </div>
                      </el-image>
                    </div>
                    <div class="line-1">
                      <span class="tag m-r-5">
                        {{ v.ltBuyAskCategoryDto.categoryName }}
                      </span>
                      <span class="title" v-html="v.askTitle"></span>
                    </div>
                    <div
                      class="f12 m-t-10"
                      v-for="(vv, ii) of v.buyAskForms"
                      :key="ii"
                      v-if="
                        ii < 2 &&
                        vv.buyAskCategoryForm.formType != 8 &&
                        vv.buyAskCategoryForm.formType != 7
                      "
                    >
                      <span class="c-2 m-r-10">
                        {{ vv.buyAskCategoryForm.formTitle }}
                      </span>
                      <span
                        class="c-6"
                        v-if="vv.buyAskCategoryForm.formType == 1"
                      >
                        {{ vv.value ? "是" : "否" }}
                      </span>
                      <span
                        class="c-6"
                        v-else-if="vv.buyAskCategoryForm.formType == 6"
                      >
                        {{ vv.value.addr }}
                      </span>
                      <span class="c-6">{{ vv.value }}</span>
                    </div>
                    <div class="f12 m-t-10">
                      <span class="c-2 m-r-10">发布时间</span>
                      <span class="c-6">{{ v.askLatestTime }}</span>
                    </div>
                    <div class="df-ai-c m-t-10 line-1">
                      <img
                      v-if="!v.askAnonymityStatus"
                        :src="$oucy.ossUrl + v.detailAvatar"
                        class="pic m-r-10"
                      />          <img
                      v-else
                        src="https://aliyunoss.lesoujia.com/lsj_mobile/image/niming.png"
                        class="pic m-r-10"
                      />
                      <span class="name f12 c-6" v-if="!v.askAnonymityStatus">{{ v.detailNickname }}</span>
                      <span class="name f12 c-6" v-else>匿名发布</span>
                    </div>
                    <div class="m-t-10 df-jc-s-b">
                      <el-button size="mini" type="primary" @click.stop="checkAuth(v)">评论抢单</el-button>
                      <el-button
                      v-if="!v.askAnonymityStatus"
                        size="mini"
                        type="success"
                        @click.stop="openImMessage(v)"
                      >
                        在线聊天
                      </el-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="" v-if="type == 2">
              <div class="box2s">
                <div
                  class="box2 df-jc-s-b"
                  v-for="(v, i) of queryFurnitureLibraryObj.content"
                  :key="i"
                  @click="$oucy.go('/askBuyDetail?id=' + v.id)"
                >
                  <div class="df">
                    <div v-if="getFormType(v.buyAskForms, 7)">
                      <div
                        v-for="(vvv, i) of v.buyAskForms"
                        :key="vvv.id"
                        v-if="
                          vvv.buyAskCategoryForm &&
                          vvv.buyAskCategoryForm.formType == 7
                        "
                        style="
                          width: 96px;
                          height: 96px;
                          background-color: #f1f1f1;
                        "
                      >
                        <template v-for="(vv, ii) of vvv.value" v-if="ii < 1">
                          <div class="bn2s">
                            <img
                              :src="$oucy.ossUrl + vv.value"
                              class="bn2"
                              v-if="vv.type == 'image'"
                            />
                            <video
                              :src="$oucy.ossUrl + vv.value"
                              class="bn2"
                              v-if="vv.type == 'video'"
                              controls
                            ></video>
                          </div>
                        </template>
                      </div>
                    </div>
                    <div
                      v-else
                      style="
                        width: 96px;
                        height: 96px;
                        background-color: #f1f1f1;
                        text-align: center;
                      "
                    >
                      <el-image style="color: #aaa">
                        <div slot="error" class="image-slot text-center m-t-10">
                          <i
                            class="el-icon-picture-outline"
                            style="font-size: 60px"
                          ></i>
                          <div class="f12">无图</div>
                        </div>
                      </el-image>
                    </div>
                    <div class="m-l-40">
                      <div class="line-1">
                        <span class="tag m-r-5">
                          {{ v.ltBuyAskCategoryDto.categoryName }}
                        </span>
                        <span class="title" v-html="v.askTitle"></span>
                      </div>
                      <div class="df m-t-10">
                        <div
                          v-for="(vv, ii) of v.buyAskForms"
                          :key="ii"
                          v-if="
                            ii < 3 &&
                            vv.buyAskCategoryForm.formType != 8 &&
                            vv.buyAskCategoryForm.formType != 7
                          "
                          class="f12 m-r-50"
                        >
                          <span class="c-2 m-r-10">
                            {{ vv.buyAskCategoryForm.formTitle }}
                          </span>
                          <span
                            class="c-6"
                            v-if="vv.buyAskCategoryForm.formType == 1"
                          >
                            {{ vv.value ? "是" : "否" }}
                          </span>
                          <span
                            class="c-6"
                            v-else-if="vv.buyAskCategoryForm.formType == 6"
                          >
                            {{ vv.value.addr }}
                          </span>
                          <span class="c-6">{{ vv.value }}</span>
                        </div>
                      </div>
                      <div class="df-ai-c m-t-10 line-1">
                        <img
                          :src="$oucy.ossUrl + v.detailAvatar"
                          class="pic m-r-10"
                        />
                        <span class="name f12 c-6">{{ v.detailNickname }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="df">
                    <div class="f12 c-6 m-r-40">{{ v.askLatestTime }}发布</div>
                    <div class="m-t-10">
                      <el-button size="mini" type="primary" @click.stop="checkAuth(v)">评论抢单</el-button>
                      <el-button
                        size="mini"
                        type="success"
                        @click.stop="openImMessage(v)"
                      >
                        在线聊天
                      </el-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <NoData
              v-if="
                !queryFurnitureLibraryObj.content ||
                !queryFurnitureLibraryObj.content.length
              "
              style="background-color: #fff"
            ></NoData>
          </div>
          <div class="want_left_main p-t-10 p-b-100">
            <div class="pagination">
              <el-pagination
                v-if="queryFurnitureLibraryObj.content"
                background
                layout="sizes, prev, pager, next, jumper, ->, total, slot"
                :page-size="queryFurnitureLibraryObj.size"
                :total="queryFurnitureLibraryObj.totalElements"
                @size-change="sizeChange"
                @current-change="currentChange"
                @prev-click="currentChange"
                :page-sizes="pageSizes"
                @next-click="currentChange"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import oucy from "../../../util/oucyUtil";
  import { adHome, buyAskCategory, buyAsk,userAuth } from "@/service";
  import { localSet, localGet, localDel } from "@/store/store";
  import NoData from "../../../components/NoData.vue";
  export default {
    name: "Index",
    data() {
      return {
        type: 1,
        noticelist: [],
        biddinglist: [],
        pageWidth: oucy.pageWidth,
        bannerHeight: 1000,
        bannerImgHeight: 560,
        bannerImgWidth: 1920,
        screenWidth: 0,
        imageBox: [],
        activeName: null,
        levelList: [],
        buyAskCategory: [{ categoryName: "最新求购", id: "0" }],
        buyAskCategoryName: [],
        queryFurnitureLibraryObj: {},
        // 请求对象
        QueryFurnitureLibrary: {
          // category: 0,
          limit: 10,
          start: 0,
          askAuditStatus: 2,
          askFinishStatus: false,
          askShelvesStatus: true,
          askCategoryId: null,
          // globalAttrOption: null,
          // globalClassId: null,
          // goodSort: 0,
          // priceMax: null,
          // priceMin: null,
          // siteId: null, //站点
        },
        pageSizes: [10, 30, 120],
        loading: true,
      };
    },
    watch: {
      $route: {
        handler(to, from) {
          this.getBreadcrumb();
        },
        deep: true,
        immediate: true,
      },
    },
    created() {
      this.getAllBuyAsk();
      this.getAllBuyAskCategory();
    },
    mounted() {
      /*        this.setSize();
                  window.onresize = () => {
                      this.setSize()
                  };*/
    },
    methods: {
      ChangeType(num) {
        this.type = num;
      },
      handleClick(v) {
        // console.log(v)
        this.QueryFurnitureLibrary.start = 0;
        // this.QueryFurnitureLibrary.askCategoryId = v
        this.getAllBuyAsk();
      },
      getBreadcrumb() {
        //$route.matched是一个数组 包含当前路由所有嵌套路径的路由记录
        let matched = this.$route.matched.filter((item) => {
          if (item.path != "/") return item.name;
        });
        const first = matched[0];
        if (first && first.name !== "首页") {
          matched = [{ path: "/", meta: { title: "首页" } }].concat(matched);
        }
        this.levelList = matched;
      },
      setSize: function () {
        // 通过浏览器宽度(图片宽度)计算高度
        this.screenWidth = window.innerWidth;
        this.bannerHeight =
          (this.bannerImgHeight / this.bannerImgWidth) * this.screenWidth;
      },
      getAllBuyAskCategory() {
        buyAskCategory.getAllBuyAskCategory().then((res) => {
          res && this.buyAskCategory.push(...res);
          // this.QueryFurnitureLibrary.askCategoryId = res[0].id
          for (let v of this.buyAskCategory) {
            this.buyAskCategoryName.push(v.categoryName);
          }
        });
      },
      // 切换页数
      sizeChange: function (pageSize) {
        const me = this;
        me.QueryFurnitureLibrary.limit = pageSize;
        me.getAllBuyAsk();
      },
      // 切换页码
      currentChange: function (current) {
        const me = this;
        me.QueryFurnitureLibrary.start = current - 1;
        me.getAllBuyAsk();
      },
      getAllBuyAsk() {
        this.loading = true;
        if (this.QueryFurnitureLibrary.askCategoryId == 0)
          this.QueryFurnitureLibrary.askCategoryId = null;
        buyAsk.getAllBuyAsk(this.QueryFurnitureLibrary).then((res) => {
          console.time("getAllBuyAsk");
          if (res && res.content) {
            for (let v of res.content) {
              if (v.buyAskForms) {
                for (let vv of v.buyAskForms) {
                  if (
                    vv.buyAskCategoryForm.formType == 7 &&
                    vv.value &&
                    typeof vv.value == "string"
                  ) {
                    vv.value = JSON.parse(vv.value);
                  } else if (vv.buyAskCategoryForm.formType == 6) {
                    vv.value = JSON.parse(vv.value);
                  } else if (vv.buyAskCategoryForm.formType == 5) {
                    vv.value = vv.chinaDto && vv.chinaDto.namePath;
                  }
                }
              }
              // v.class=this.getClass(v)
            }
          }
          this.loading = !true;
          this.queryFurnitureLibraryObj = res;
          console.timeEnd("getAllBuyAsk");
        });
      },
      getClass(v) {
        return (
          "status_" +
          this.buyAskCategoryName.indexOf(v.ltBuyAskCategoryDto.categoryName)
        );
        /*for (var i = 0; i < this.buyAskCategory.length; i++) {
                    if(this.buyAskCategory[i].categoryName==v.ltBuyAskCategoryDto.categoryName){
                        return 'status_'+i
                    }
                }*/
      },
      openImMessage(v) {
        this.loginUser = localGet("loginUser");
        if (this.loginUser && this.loginUser.id) {
          userAuth.queryUserBuyAskActionIsAuth({
                buyAskActionEnum: 1 // 求购操作类型 1聊天 2拨号 3查看
            }).then((res) =>{ 
              if(res) {
                this.$oucy.app.eventBus.$emit(this.$oucy.msgKeys.OPEN_IM_MESSAGE, {
                  targetUserId: v.askUser,
                });
              } else {
                this.$alert(res) 
              }
            })
          
        } else {
          this.$alert("请先登录", "提示");
        }
        // this.$emit(this.$oucy.msgKeys.OPEN_IM_MESSAGE,v);
      },
      getFormType(v, t) {
        let has = false;
        if (v) {
          for (let vv of v) {
            if (vv.buyAskCategoryForm && vv.buyAskCategoryForm.formType == t) {
              if(vv.value && vv.value.length){
                has = true;
              }
              break;
            }
          }
        }
        return has;
      },

      checkAuth(value){
        userAuth.queryUserBuyAskActionIsAuth({
            buyAskActionEnum: 3 // 求购操作类型 1聊天 2拨号 3查看
        }).then((res) =>{
          if(res) {
            this.$oucy.go('/askBuyDetail?id=' + value.id)
          } else {
            this.$alert(res) 
          }
        })
      }
    },
    components: { NoData },
  };
</script>
<style scoped lang="less">
  .breadcrumbBarBox {
    background: #fff;
  }

  .carousel img {
    /*设置图片宽度和浏览器宽度一致*/
    width: 100%;
    height: inherit;
  }

  .want_main {
    // background: #fff;
    margin: 0 auto;
  }

  .main {
  }

  .boxs {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .box {
    background: #fff;
    padding: 0 25px 25px 25px;
    // width: 33%;
    width: 420px;
    box-sizing: border-box;
    margin-left: 10px;
    margin-bottom: 10px;
  }

  .box:nth-child(3n-2) {
    // background:#f00;
    margin-left: 0px;
  }

  .product_title {
    display: inline-block;
    border-radius: 2px;
    color: #fff;
    padding: 6px 10px;
    font-weight: 600;

    &.status_0,
    &.status_1,
    &.status_4,
    &.status_7,
    &.status_9 {
      background: #e78238;
    }

    &.status_2,
    &.status_5,
    &.status_8,
    &.status_10 {
      background: #49a7ff;
    }

    &.status_3,
    &.status_6,
    &.status_9,
    &.status_11 {
      background: #eb3c3c;
    }
  }

  .product_tag {
    display: flex;

    flex-wrap: wrap;

    div {
      background: rgba(255, 255, 255, 0);
      border: 1px solid #ea8840;
      border-radius: 2px;
      color: #ea8840;
      font-size: 12px;
      padding: 0 4px;
      margin-right: 6px;
      margin-bottom: 5px;
    }
  }

  .hiedImg {
    width: 19px;
    height: 19px;
    border-radius: 50%;
  }

  .spuImg {
    width: 104px;
    height: 104px;
    margin-right: 10px;
  }

  .breadcrumbBar {
    padding: 10px 0;
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .middle_middle {
    height: 52px;
    line-height: 52px;
    background-color: #ffffff;
    margin: 0px 0 10px;
  }

  .middle_middle span {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-right: 10px;
  }

  .middle_middle .el-input {
    width: 97px;
    margin-right: 10px;
  }

  .wangge {
    float: right;
    margin-right: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .wangge span {
    font-size: 14px;
    margin-left: 8px;
    color: #666666;
  }

  .box1s {
    display: flex;
    /*justify-content: space-around;*/
    flex-wrap: wrap;
    background-color: #fff;
  }
  .box1Box {
    width: 20%;
  }
  .box1 {
    /*width: 200px;*/
    padding: 20px;
    cursor: pointer;
  }
  .bns{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }
  .bn {
    max-width: 100%;
    max-height: 100%;
    /*width: auto;*/
  }

  .tag {
    font-size: 12px;
    font-family: SimSun;
    font-weight: 400;
    color: #ffffff;
    background: #e95f29;
    border-radius: 2px;
    display: inline-block;
    padding: 4px 7px;
  }

  .title {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #111111;
  }

  .pic {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    /*margin-right: 10px;*/
  }
  .box2s {
    background-color: #fff;
  }
  .box2 {
    padding: 20px;
    cursor: pointer;
  }
  .bn2s{
    position: relative;
    height: 96px;
    width: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bn2 {
    max-width: 100%;
    max-height: 100%;
/*    width: auto;
    margin-left: auto;
    margin-right: auto;*/
  }
  .pagination {
    margin-top: 50px;
    line-height: 150px;
  }
  .imageVideoBox{
      position: relative;
      width: 100%;
      height: 100%;
      .imageVideo{
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
      }

  }
</style>
<style type="text/css">
  .aksBuyTabs .el-tabs__item {
    font-size: 16px;
    font-weight: bold;
  }
</style>
